$solar-color: #FF5000;
$tuinbouw-color: #006eb6;

$primary: $solar-color;

$active-color: $primary;

$navbar-active-color: #343a40;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "footer";

.text-solar {
  color: $solar-color;
}
.text-tuinbouw {
  color: $tuinbouw-color;
}
.slogan {
  color: $solar-color;
}

.navbar-nav > .active > .nav-link {
  font-weight: bold;
  color: $navbar-active-color !important;
}

.dropdown.nav-item {
  .nav-link.active {
    font-weight: bold;
    color: #343a40 !important;
  }
}

.navbar-only, .navbar-main {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.jumbotron-header {
  background: url(../img/werkenbijsmith-banner.jpg) center;
  background-size: cover;
}

.container {
  &.container-sm {
    max-width: 720px !important;
  }
}
